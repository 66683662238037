import config from 'config';
import rootStore from '@vue-storefront/core/store';
import SearchQuery from '@vue-storefront/core/lib/search/searchQuery';

export async function prepareQuickSearchQuery(queryText) {
  let searchQuery = new SearchQuery();

  // TODO: Determine if default filters are required and their expected values
  // TODO: Make default filters configurable.
  searchQuery = searchQuery
    .setSearchText(queryText)
    .applyFilter({ key: 'visibility', value: { in: config.zento.queryFilters.search.visibility } })
    .applyFilter({ key: 'status', value: { in: config.zento.queryFilters.search.status } });

  if (config.zento.theme.category.listOutOfStockProducts === false) {
    searchQuery = searchQuery.applyFilter({ key: 'stock.is_in_stock', value: { eq: true } });
  }

  if (config.zento.theme.category.filtersOnSearch) {
    // load filter attributes for this specific search (only those set to be used in search requests in admin and if not already populated in state)
    await rootStore.dispatch('attribute/list', {
      filterable_in_search: true,
      includeFields:
        config.entities.optimize && rootStore.getters['application-context/isServer']
          ? config.entities.attribute.includeFields
          : null,
    });

    const searchFilters =
      'searchFilters' in config.zento.theme.category && config.zento.theme.category.searchFilters.length
        ? config.zento.theme.category.searchFilters
        : config.zento.theme.category.defaultFilters.length
        ? config.zento.theme.category.defaultFilters
        : [];

    if (searchFilters.length) {
      // load additional attributes set in overwrite config
      await rootStore.dispatch('attribute/list', {
        filterValues: searchFilters,
        filterable_in_search: false,
        only_visible: false,
        only_filterable: false,
        includeFields:
          config.entities.optimize && rootStore.getters['application-context/isServer']
            ? config.entities.attribute.includeFields
            : null,
      });
    }

    const filters = Object.keys(rootStore.state.attribute.list_by_code);

    // Add available catalog filters
    for (const attrToFilter of filters) {
      searchQuery = searchQuery.addAvailableFilter({ field: attrToFilter, scope: 'catalog' });
    }
  }

  return searchQuery;
}
